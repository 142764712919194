class AuthService {
  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("authToken");
  }

  getCurrentUser() {
    const user = localStorage.getItem("user");
    return user ? JSON.parse(user) : false;
  }

  loggedIn() {
    return !!localStorage.getItem("user");
  }

  isDarkModeEnable() {
    const darkMode = localStorage.getItem("darkmode");
    return darkMode ? JSON.parse(darkMode) === true : false;
  }
}

const authServiceInstance = new AuthService();

export default authServiceInstance;
