import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonSpinner,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonButton,
} from "@ionic/react";
import "./Tab1.css";
import { gql, useQuery } from "@apollo/client";
import AuthService from "../../services/auth.service";
import { useTranslation } from "react-i18next";
import {
  getRandomColor,
  createImageFromInitials,
} from "../../components/Utils";
import config from "../../config";

const GET_USER = gql`
  query usuario($usuarioId: String) {
    viewer {
      usuario(usuarioId: $usuarioId) {
        id
        habitaDueno {
          id
          habitacion {
            id
            nombre
            superficie
            cuota
            referenciaBancaria
            saldo
            habitaDueno {
              dueno {
                id
                nombre
                paterno
                materno
                email
              }
            }
            tipoVivienda {
              id
              nombre
            }
            condominio {
              id
              empresa
            }
            torre {
              id
              nombre
              condominio {
                id
                empresa
              }
            }
            cuotas(filters: { estatusNe: "P" }) {
              edges {
                node {
                  id
                  residente {
                    id
                    nombre
                  }
                  formaCuota
                  monto
                  fecha
                  concepto
                  estatus
                  cuotaXMetro
                  contTipoCuota {
                    id
                    nombre
                  }
                  pagos {
                    edges {
                      node {
                        id
                        recibo
                        monto
                      }
                    }
                  }
                  recargos(filters: { estatus: "D" }) {
                    edges {
                      node {
                        id
                        deudaDe
                        deudaA
                        otrasDeudasVigentes
                        cantidad
                        cantidadTipo
                        aplicarA
                        soloMayoresA
                        conContCuotas {
                          id
                          monto
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_AREAS_COMUNES = gql`
  query ($condominioId: Int!) {
    viewer {
      allAreasComunes(
        filters: { condominioId: $condominioId, enServicio: "S" }
      ) {
        edges {
          node {
            id
            nombre
            imagen
            enServicio
            condominio {
              id
              empresa
            }
          }
        }
      }
    }
  }
`;

const pagoCompletoStyle = {
  backgroundColor: "rgba(60, 179, 113, 0.2)",
};
const pagoIncompletoStyle = {
  backgroundColor: "rgba(255, 99, 71, 0.2)",
};

const AreasComunesTab: React.FC = () => {
  const { t } = useTranslation();
  const [currentUser, setCurrentUser] = useState(AuthService.getCurrentUser());
  const [condominioId, setCondominioId] = useState(
    currentUser.condominioId ? currentUser.condominioId : 0
  );
  const [saldoAfavor, setSaldoAfavor] = useState(0);
  const [hasSaldo, setHasSaldo] = useState(false);
  const [showAbonoColumn, setShowAbonoColumn] = useState(false);
  const [cuotasPendientes, setCuotasPendientes] = useState<any[]>([]);
  const [totalPagoPendiente, setTotalPagoPendiente] = useState(0);
  const [saldo, setSaldo] = useState(0);

  const { client, loading, data, error, refetch } = useQuery(
    GET_AREAS_COMUNES,
    {
      variables: { condominioId: condominioId },
    }
  );

  useEffect(() => {
    if (!loading) {
    }
  }, [loading]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle size="large">
            {t("list")} [{t("amenities")}]
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {loading ? (
          <div className="spin">
            <IonSpinner></IonSpinner>
          </div>
        ) : (
          data.viewer.allAreasComunes.edges.map((record: any) => (
            <IonCard key={record.node.id}>
              {record.node.imagen ? (
                <img
                  style={{ maxWidth: "100%", height: "186px", width: "100%" }}
                  src={`${config.API_URL}${record.node.imagen}`}
                  alt={record.node.nombre}
                />
              ) : (
                <img
                  style={{ maxWidth: "100%", height: "186px", width: "100%" }}
                  src={createImageFromInitials(
                    240,
                    record.node.nombre,
                    getRandomColor()
                  )}
                  alt={record.node.nombre}
                />
              )}
              <IonCardHeader>
                <IonCardTitle>{record.node.nombre}</IonCardTitle>
              </IonCardHeader>

              <IonCardContent>{record.node.descripcion}</IonCardContent>

              <IonButton
                fill="outline"
                expand="block"
                routerLink={`/page/areacomun/${record.node.id}`}
              >
                {t("select")} {t("amenity")}
              </IonButton>
            </IonCard>
          ))
        )}
      </IonContent>
    </IonPage>
  );
};

export default AreasComunesTab;
