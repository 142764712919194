import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonAvatar,
  IonTitle,
  IonButton,
  IonIcon,
  IonContent,
  IonFooter,
  IonInput,
  IonSpinner,
  IonThumbnail,
  IonSkeletonText,
} from "@ionic/react";
import {
  thumbsUpSharp,
  call,
  videocam,
  addCircle,
  camera,
  image,
  mic,
  chevronForward,
  send as sendIcon,
} from "ionicons/icons";
import { getRandomColor, createImageFromInitials } from "../components/Utils";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useParams, useHistory } from "react-router";
import AuthService from "../services/auth.service";

const GET_TICKET_DATA = gql`
  query ticket($ticketId: String) {
    viewer {
      ticket(ticketId: $ticketId) {
        id
        condominioId
        habitacionId
        habitacion {
          id
          nombre
          habitaResidente {
            residente {
              id
              nombre
              paterno
              materno
            }
          }
        }
        departamentoId
        departamento {
          id
          nombre
        }
        usuarioId
        cuotaId
        sugerencia
        fecha
        prioridad
        estatus
        asunto
        contenido
        comentarios {
          edges {
            node {
              id
              respuesta
              fecha
              usuarioId
              usuario {
                id
                username
                nombre
                paterno
                materno
                color
              }
            }
          }
        }
      }
    }
  }
`;

const ChatList = styled.div`
  .chat-item {
    margin-bottom: 8px;

    &.chat-item-sender {
      text-align: right;

      .chat-item-content {
        justify-content: flex-end;
      }

      .chat-body {
        color: #fff;
        background: var(--ion-color-primary);
        border-radius: 20px;
        border-color: transparent;
      }
    }
  }

  .chat-timestamp {
    text-align: center;
    font-size: 13px;
    padding: 8px;
    color: var(--ion-color-medium);
    text-transform: uppercase;
  }

  .chat-item-status {
    font-size: 13px;
    color: var(--ion-color-medium);
    margin-right: 8px;
  }

  .chat-item-content {
    display: flex;
    flex-direction: row;
    margin-bottom: 4px;

    .chat-avatar {
      margin-right: 8px;
      align-self: flex-end;

      img {
        display: inline-block;
        width: 100%;
        border-radius: 50%;
      }
    }
  }

  .chat-item-bubble {
    position: relative;
    max-width: 75%;

    .chat-body {
      display: inline-block;
      background: var(--ion-color-light);
      border-radius: 20px;
      padding: 12px 16px;
      white-space: normal;
      max-width: 100%;
      text-align: left;
    }

    &.bubble-image {
      font-size: 0;

      img {
        width: 100%;
        max-width: 480px;
        border-radius: 16px;
      }
    }
  }

  .chat-item-reaction {
    text-align: left;
    margin-left: 8px;
    font-size: 18px;
    align-self: center;
    flex-grow: 99;
  }
`;

const MessageInput = styled(IonInput)`
  --background: var(--ion-color-light);
  --padding-start: 12px;
  --padding-end: 12px;
  border-radius: 20px;
`;

const CREATE_TICKET_COMENTARIO = gql`
  mutation CreateTicketsComentarioMutation(
    $ticketscomentarioData: TicketsComentariosInput!
  ) {
    createTicketsComentario(ticketscomentarioData: $ticketscomentarioData) {
      ticketscomentario {
        id
        respuesta
        fecha
        usuarioId
        usuario {
          id
          username
          nombre
          paterno
          materno
          color
        }
      }
    }
  }
`;

const ChatPage: React.FC = () => {
  const avatarClient = createImageFromInitials(
    240,
    "Reciber",
    getRandomColor()
  );
  const { ticketId } = useParams<{ ticketId: string }>();
  const [currentUser, setCurrentUser] = useState(AuthService.getCurrentUser());
  const usuarioId = currentUser.id;
  const [messages, setMessages] = useState([
    {
      isSender: false,
      avatar: avatarClient,
      type: "text", // text || image
      body: "Loading",
      timestamp: "May 2, 2020 9:48 AM",
    },
  ]);
  const chatListRef = useRef<HTMLDivElement>(null);
  const chatContentRef = useRef<HTMLIonContentElement>(null);
  /** 
    {
            isSender: false,
            avatar: avatarClient,
            type: 'text', // text || image
            body: 'Hey yo what\'s up?',
            timestamp: 'May 2, 2020 9:48 AM'
        },
        {
            isSender: true,
            type: 'image', // text || image
            body: 'https://media.giphy.com/media/1g0Nz8xkYaqiBBYyLe/giphy.gif',
            timestamp: 'May 2, 2020 9:50 AM'
        },
        {
            isSender: false,
            avatar: avatarClient,
            type: 'image', // text || image
            body: 'https://media.giphy.com/media/4BWByhavc3Hr2/giphy.gif',
            timestamp: 'May 2, 2020 9:52 AM'
        },
        {
            isSender: true,
            type: 'text', // text || image
            body: 'Where are you, buddy?',
            timestamp: 'May 2, 2020 9:53 AM'
        },
        {
            isSender: false,
            avatar: avatarClient,
            type: 'text', // text || image
            body: 'I\'m almost there',
            timestamp: 'May 2, 2020 9:53 AM'
        } 
    **/
  const [messageInput, setMessageInput] = useState("");
  const [isFocus, setIsFocus] = useState(false);
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(false);

  const toggleFocus = (focus: boolean) => {
    setIsFocus(focus);
  };

  const nl2br = (text: string) => {
    if (!text) return text;
    return text.replace(/\n/gi, "<br>");
  };

  function getColor(estatus: string) {
    switch (estatus) {
      case "P":
        return "#FFD534";
      case "A":
        return "#2FDF75";
      case "T":
        return "#FF4961";
      case "C":
        return "#428CFF";
      default:
        return "#428CFF";
    }
  }

  const history = useHistory();

  const { loading, error, data } = useQuery(GET_TICKET_DATA, {
    variables: { ticketId },
    onCompleted: (data) => {
      setMessages([]);
      setMessages((currentMessages) => [
        ...currentMessages,
        ...data.viewer.ticket.comentarios.edges.map((comment: any) => {
          const timestamp = new Date(comment.node.fecha);
          const formattedTimestamp = timestamp.toLocaleString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          });

          return {
            isSender: comment.node.usuario.id === usuarioId ? true : false,
            avatar: createImageFromInitials(
              240,
              comment.node.usuario.nombre,
              comment.node.usuario.color ?? "#2FDF75"
            ),
            type: "text", // text || image
            body: comment.node.respuesta,
            timestamp: formattedTimestamp,
          };
        }),
      ]);
      setShouldScrollToBottom(true);
    },
    onError: (error) => {
      // Handle error
    },
  });

  const handleSendMessage = () => {
    const timestamp = new Date();
    createTicketComentario({
      variables: {
        ticketscomentarioData: {
          ticketId: ticketId,
          respuesta: messageInput,
          enviarCorreo: true,
        },
      },
    });
    const formattedTimestamp = timestamp.toLocaleString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    setMessages((currentMessages) => [
      ...currentMessages,
      {
        isSender: true,
        avatar: "",
        type: "text", // text || image
        body: messageInput,
        timestamp: formattedTimestamp,
      },
    ]);
    setMessageInput("");
    setShouldScrollToBottom(true);
  };

  const [
    createTicketComentario,
    {
      data: dataComentario,
      loading: loadingComentario,
      error: errorComentario,
      reset,
    },
  ] = useMutation(CREATE_TICKET_COMENTARIO, {
    onError(error) {},
    onCompleted(data) {},
  });

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevents the newline character from being added to the input

      // Call your sendMessage function here
      handleSendMessage();
    }
  };

  useEffect(() => {
    if (shouldScrollToBottom && chatContentRef.current) {
      chatContentRef.current.scrollToBottom();
      setShouldScrollToBottom(false);
    }
  }, [shouldScrollToBottom]);

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/page/tickets" />

            {loading ? (
              <IonThumbnail slot="start">
                <IonSkeletonText animated={true}></IonSkeletonText>
              </IonThumbnail>
            ) : (
              <IonAvatar className="avatar-sm ion-margin-start">
                <img
                  src={createImageFromInitials(
                    240,
                    data.viewer.ticket.asunto,
                    getColor(data.viewer.ticket.estatus)
                  )}
                  alt=""
                />
              </IonAvatar>
            )}
          </IonButtons>

          {loading ? (
            <IonSpinner className="ion-text-left" name="dots"></IonSpinner>
          ) : (
            <IonTitle className="ion-text-left">
              {data.viewer.ticket.asunto}
            </IonTitle>
          )}

          <IonButtons slot="end">
            <IonButton>
              <IonIcon slot="icon-only" icon={call} />
            </IonButton>
            <IonButton>
              <IonIcon slot="icon-only" icon={videocam} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding" ref={chatContentRef}>
        {loading ? (
          <div className="spin">
            <IonSpinner></IonSpinner>
          </div>
        ) : (
          <ChatList className="chat-list" ref={chatListRef}>
            {messages.map((item, index) => (
              <div
                key={index}
                className={`chat-item ${
                  item.isSender ? "chat-item-sender" : ""
                }`}
              >
                <div className="chat-timestamp">{item.timestamp}</div>

                <div className="chat-item-content">
                  <div
                    className="chat-avatar"
                    style={{ display: !item.isSender ? "block" : "none" }}
                  >
                    <IonAvatar className="avatar-sm">
                      <img src={item.avatar} alt="" />
                    </IonAvatar>
                  </div>

                  <div
                    className={`chat-item-bubble ${
                      item.type === "image" ? "bubble-image" : ""
                    }`}
                  >
                    <div
                      className="chat-body"
                      style={{
                        display: item.type !== "image" ? "block" : "none",
                      }}
                      dangerouslySetInnerHTML={{ __html: nl2br(item.body) }}
                    ></div>
                    <img
                      src={item.body}
                      style={{
                        display: item.type === "image" ? "block" : "none",
                      }}
                      alt=""
                    />
                  </div>

                  <div
                    className="chat-item-reaction"
                    style={{
                      display:
                        !item.isSender && item.type === "image"
                          ? "block"
                          : "none",
                    }}
                  >
                    <IonIcon name="share-outline" />
                  </div>
                </div>

                <div
                  className="chat-item-status"
                  style={{ display: item.isSender ? "block" : "none" }}
                >
                  Seen
                </div>
              </div>
            ))}
          </ChatList>
        )}
      </IonContent>

      <IonFooter className="ion-no-border">
        <IonToolbar>
          <IonButtons
            slot="start"
            style={{ display: !isFocus ? "flex" : "none" }}
          >
            <IonButton>
              <IonIcon slot="icon-only" icon={addCircle} />
            </IonButton>
            <IonButton>
              <IonIcon slot="icon-only" icon={camera} />
            </IonButton>
            <IonButton>
              <IonIcon slot="icon-only" icon={image} />
            </IonButton>
            <IonButton>
              <IonIcon slot="icon-only" icon={mic} />
            </IonButton>
          </IonButtons>

          <IonButtons
            slot="start"
            style={{ display: isFocus ? "flex" : "none" }}
          >
            <IonButton onClick={handleSendMessage}>
              <IonIcon slot="icon-only" icon={chevronForward} />
            </IonButton>
          </IonButtons>

          <MessageInput
            className="message-input"
            placeholder="Aa"
            value={messageInput}
            onIonFocus={() => toggleFocus(true)}
            onIonBlur={() => toggleFocus(false)}
            onIonChange={(e) => setMessageInput(e.detail.value!)}
            /*onKeyPress={(e) => (handleKeyPress as any)(e)}*/
          />

          <IonButtons slot="end">
            <IonButton onClick={handleSendMessage}>
              <IonIcon slot="icon-only" icon={sendIcon} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default ChatPage;
