import {
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";
import {
  card as cardIcon,
  wallet as walletIcon,
  time as timeIcon,
  barChart as barChartIcon,
} from "ionicons/icons";
import React from "react";
import { Redirect, Route } from "react-router-dom";
import SaldoTab from "./finanzas/SaldoTab";
import EstadoDeCuentaTab from "./finanzas/EstadoDeCuentaTab";
import MisPagosTab from "./finanzas/MisPagosTab";
import HistoricoTab from "./finanzas/HistoricoTab";
import { useTranslation, withTranslation } from "react-i18next";

const FinanzasTabsPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <IonTabs>
      <IonRouterOutlet>
        <Route exact path="/page/finanzas/saldo">
          <SaldoTab />
        </Route>
        <Route exact path="/page/finanzas/estadodecuenta">
          <EstadoDeCuentaTab />
        </Route>
        <Route exact path="/page/finanzas/mispagos">
          <MisPagosTab />
        </Route>
        <Route exact path="/page/finanzas/historico">
          <HistoricoTab />
        </Route>
        <Route exact path="/page/finanzas">
          <Redirect to="/page/finanzas/saldo" />
        </Route>
      </IonRouterOutlet>
      <IonTabBar slot="bottom">
        <IonTabButton tab="tab1" href="/page/finanzas/saldo">
          <IonIcon icon={walletIcon} />
          <IonLabel>{t("balance")}</IonLabel>
        </IonTabButton>
        <IonTabButton tab="tab2" href="/page/finanzas/estadodecuenta">
          <IonIcon icon={barChartIcon} />
          <IonLabel>{t("outstanding-balance")}</IonLabel>
        </IonTabButton>
        <IonTabButton tab="tab3" href="/page/finanzas/mispagos">
          <IonIcon icon={cardIcon} />
          <IonLabel>{t("my-payments")}</IonLabel>
        </IonTabButton>
        <IonTabButton tab="tab4" href="/page/finanzas/historico">
          <IonIcon icon={timeIcon} />
          <IonLabel>{t("balance-history")}</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default withTranslation()(FinanzasTabsPage);
