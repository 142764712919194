import { Redirect, Route } from "react-router-dom";
import React, { Suspense } from "react";
import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  setupIonicReact,
  IonSpinner,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import AppTabs from "./AppTabs";
import FinanzasTabsPage from "./pages/FinanzasTabsPage";
import AreasComunesTabsPage from "./pages/AreasComunesTabsPage";
import AirbnbTabsPage from "./pages/AirbnbTabsPage";
import AirbnbPage from "./pages/airbnb/AirbnbPage";

import AreaComunPage from "./pages/areasComunes/AreaComunPage";
import MorososPage from "./pages/MorososPage";
import ConfiguracionPage from "./pages/ConfiguracionPage";

import AnunciosPage from "./pages/AnunciosPage";
import CategoriasPage from "./pages/CategoriasPage";
import BlogPage from "./pages/BlogPage";
import PagosPage from "./pages/PagosPage";
import TicketsPage from "./pages/TicketsPage";
import CreateTicketPage from "./pages/CreateTicketPage";
import SubirComprobantePage from "./pages/SubirComprobantePage";
import LogOutPage from "./pages/LogOutPage";
import ChatPage from "./pages/Chat";

import NotFoundPage from "./pages/NotFoundPage";
import Menu from "./components/Menu";
import AuthService from "./services/auth.service";

import VisitantesPage from "./pages/VisitantesPage";
import VisitantesPageEdit from "./pages/visitantes/VisitantesPageEdit";

/* Theme variables */
import "./theme/variables.css";
import Signup from "./pages/Signup";
import Login from "./pages/Login";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import { withTranslation } from "react-i18next";

setupIonicReact();
const App: React.FC = () => {
  const loggedIn = AuthService.loggedIn();
  const isDarkModeEnable = AuthService.isDarkModeEnable();
  console.log("isDarkModeEnable", isDarkModeEnable);

  if (isDarkModeEnable) {
    document.body.classList.add("dark");
  } else {
    document.body.classList.remove("dark");
  }

  return (
    <Suspense
      fallback={
        <div className="spin">
          <IonSpinner></IonSpinner>
        </div>
      }
    >
      <IonApp>
        <>
          {loggedIn === true ? (
            <IonReactRouter>
              <IonSplitPane contentId="main">
                <Menu />
                <IonRouterOutlet id="main">
                  <Route exact path="/page/anuncios">
                    <AnunciosPage pageName="Categorias" />
                  </Route>

                  <Route exact path="/page/anuncios/:categoriablogId">
                    <CategoriasPage pageName="Nombre de la categoria" />
                  </Route>

                  <Route exact path="/page/blog/:blogDescripcionId">
                    <BlogPage pageName="Nombre del Blog" />
                  </Route>

                  <Route exact path="/page/pagos">
                    <PagosPage pageName="Pagos" />
                  </Route>

                  <Route exact path="/page/tickets">
                    <TicketsPage pageName="Tickets & Sugerencias" />
                  </Route>

                  <Route exact path="/page/tickets/create">
                    <CreateTicketPage pageName="Nuevo Ticket o Sugerencia" />
                  </Route>

                  <Route exact path="/page/visitantes">
                    <VisitantesPage pageName="Visitas" />
                  </Route>

                  <Route exact path="/page/visitantes/create">
                    <VisitantesPageEdit pageName="Nuevo Visitante" />
                  </Route>

                  <Route path="/page/visitantes/:bitacoraVisitanteId">
                    <VisitantesPageEdit pageName="Airbnb" />
                  </Route>

                  <Route exact path="/page/tickets/comentar/:ticketId">
                    <ChatPage />
                  </Route>

                  <Route exact path="/page/pagos/subir">
                    <SubirComprobantePage pageName="Nuevo Comprobante de Pago" />
                  </Route>

                  <Route exact path="/page/configuracion">
                    <ConfiguracionPage pageName="Configuración" />
                  </Route>

                  <Route exact path="/page/morosos">
                    <MorososPage pageName="Morosos" />
                  </Route>

                  <Route path="/home">
                    <AppTabs />
                  </Route>

                  <Route path="/page/finanzas">
                    <FinanzasTabsPage />
                  </Route>

                  <Route path="/page/areascomunes">
                    <AreasComunesTabsPage />
                  </Route>

                  <Route path="/page/airbnb">
                    <AirbnbTabsPage />
                  </Route>

                  <Route path="/page/airbnbs/:airbnbReservaId">
                    <AirbnbPage pageName="Airbnb" />
                  </Route>

                  <Route path="/page/areacomun/:areaComunId">
                    <AreaComunPage pageName="Área Comun" />
                  </Route>

                  <Route exact path="/page/pagos/subir">
                    <SubirComprobantePage pageName="Nuevo Comprobante de Pago" />
                  </Route>

                  <Route exact path="/">
                    <Redirect to="/page/anuncios" />
                  </Route>
                  <Route exact path="/logout">
                    <LogOutPage />
                  </Route>
                  <Route exact path="/login">
                    <Redirect to="/home" />
                  </Route>
                  <Route>
                    <NotFoundPage />
                  </Route>
                </IonRouterOutlet>
              </IonSplitPane>
            </IonReactRouter>
          ) : (
            <IonReactRouter>
              <Route exact path="/signup">
                <Signup />
              </Route>

              <Route exact path="/login">
                <Login />
              </Route>
              <Redirect from="/" to="/login" exact />
            </IonReactRouter>
          )}
        </>
      </IonApp>
    </Suspense>
  );
};

export default withTranslation()(App);
