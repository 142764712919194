import {
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";
import { business as businessIcon, time as timeIcon } from "ionicons/icons";
import React from "react";
import { Redirect, Route } from "react-router-dom";
import AirbnbTab from "./airbnb/AirbnbTab";
import ReservacionesTab from "./airbnb/ReservacionesTab";
import { useTranslation } from "react-i18next";

const AirbnbTabsPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <IonTabs>
      <IonRouterOutlet>
        <Route exact path="/page/airbnb/airbnb">
          <AirbnbTab />
        </Route>
        <Route exact path="/page/airbnb/reservaciones">
          <ReservacionesTab />
        </Route>
        <Route exact path="/page/airbnb">
          <Redirect to="/page/airbnb/reservaciones" />
        </Route>
      </IonRouterOutlet>
      <IonTabBar slot="bottom">
        {/*<IonTabButton tab="tab1" href="/page/airbnb/airbnb">
          <IonIcon icon={businessIcon} />
          <IonLabel>Habitaciones</IonLabel>
        </IonTabButton>*/}
        <IonTabButton tab="tab2" href="/page/airbnb/reservaciones">
          <IonIcon icon={timeIcon} />
          <IonLabel>{t("my-bookings")}</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default AirbnbTabsPage;
