import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonItem,
  IonIcon,
  IonSpinner,
  IonButton,
  IonButtons,
  IonMenuButton,
  IonLabel,
  useIonViewWillEnter,
  IonBadge,
  IonList,
} from "@ionic/react";
import React, { useState } from "react";
import { add as addIcon } from "ionicons/icons";
import { gql, useQuery } from "@apollo/client";
import Moment from "moment";
import "moment/locale/es";
import AuthService from "../services/auth.service";
import { useTranslation } from "react-i18next";
import "./Pages.css";

const GET_USER = gql`
  query usuario($usuarioId: String) {
    viewer {
      usuario(usuarioId: $usuarioId) {
        id
        habitaResidente {
          habitacion {
            tickets {
              edges {
                node {
                  id
                  departamentoId
                  departamento {
                    id
                    nombre
                  }
                  usuarioId
                  cuotaId
                  sugerencia
                  fecha
                  prioridad
                  estatus
                  asunto
                  contenido
                  comentarios {
                    edges {
                      node {
                        id
                        respuesta
                        fecha
                        usuarioId
                        usuario {
                          id
                          username
                          nombre
                          paterno
                          materno
                          color
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        tickets(sort: FECHA_DESC) {
          edges {
            node {
              id
              condominioId
              habitacionId
              habitacion {
                id
                nombre
                habitaResidente {
                  residente {
                    id
                    nombre
                    paterno
                    materno
                  }
                }
              }
              departamentoId
              departamento {
                id
                nombre
              }
              usuarioId
              cuotaId
              sugerencia
              fecha
              prioridad
              estatus
              asunto
              contenido
              comentarios {
                edges {
                  node {
                    id
                    respuesta
                    fecha
                    usuarioId
                    usuario {
                      id
                      username
                      nombre
                      paterno
                      materno
                      color
                    }
                  }
                }
              }
            }
          }
        }
        bitacoraIncidencias(sort: FECHA_REGISTRO_DESC) {
          edges {
            node {
              incidencia {
                id
                nombre
              }
              fechaIncidencia
              solucion
              estatus
            }
          }
        }
      }
    }
  }
`;

interface PageProps {
  pageName: string | undefined;
}

const TicketsPage: React.FC<PageProps> = ({ pageName }, location) => {
  const { t } = useTranslation();
  const [currentUser, setCurrentUser] = useState(AuthService.getCurrentUser());
  const [lang, setLang] = useState<string>(
    localStorage.getItem("langSelect")
      ? localStorage.getItem("langSelect")!
      : "en"
  );
  const [tickets, setTickets] = useState([]);

  const { loading, error, data, refetch } = useQuery(GET_USER, {
    variables: { usuarioId: currentUser.id },
    onCompleted(data) {
      if (data.viewer.usuario.habitaResidente) {
        setTickets(
          data.viewer.usuario.habitaResidente.habitacion.tickets.edges
        );
      }
    },
    onError(error) {
      //reset();
    },
  });

  useIonViewWillEnter(() => {
    refetch();
  });

  function getColor(estatus: string) {
    switch (estatus) {
      case "P":
        return { color: "danger", label: t("pending") };
      case "A":
        return { color: "warning", label: t("approved") };
      case "T":
        return { color: "danger", label: t("pending") };
      case "C":
        return { color: "danger", label: t("cancelled") };
      case "R":
        return { color: "success", label: t("success") };
      default:
        return { color: "danger", label: t("cancelled") };
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{t("tickets")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {loading ? (
          <div className="spin">
            <IonSpinner></IonSpinner>
          </div>
        ) : (
          <>
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>{t("tickets")}</IonCardTitle>
              </IonCardHeader>

              <IonCardContent>
                {t("tickets-information")}
                <IonList>
                  <IonItem>
                    <IonLabel>{t("solved-status-label")}</IonLabel>
                    <IonBadge color="success">{t("solved-status")}</IonBadge>
                  </IonItem>
                  <IonItem>
                    <IonLabel>{t("active-status-label")} </IonLabel>
                    <IonBadge color="warning">{t("active-status")}</IonBadge>
                  </IonItem>
                  <IonItem>
                    <IonLabel>{t("pending-status-label")} </IonLabel>
                    <IonBadge color="danger">{t("pending-status")}</IonBadge>
                  </IonItem>
                </IonList>
              </IonCardContent>

              <IonButton
                fill="outline"
                expand="block"
                routerLink={"/page/tickets/create"}
              >
                <IonIcon icon={addIcon} slot="start" />
                {t("new-ticket")}
              </IonButton>
            </IonCard>

            {data.viewer.usuario.habitaResidente &&
              data.viewer.usuario.habitaResidente.habitacion.tickets.edges.map(
                (record: any) => (
                  <IonCard key={record.node.id}>
                    <IonCardHeader>
                      <IonCardSubtitle>
                        {Moment(record.node.fecha).locale(lang).format("LLLL")}{" "}
                      </IonCardSubtitle>
                      <IonCardTitle color={getColor(record.node.estatus).color}>
                        {record.node.asunto}{" "}
                        <IonItem>
                          <IonBadge
                            color={getColor(record.node.estatus).color}
                            slot="end"
                          >
                            {record.node.comentarios.edges.length}
                          </IonBadge>
                          <IonLabel slot="end">Comentarios</IonLabel>
                        </IonItem>
                      </IonCardTitle>
                    </IonCardHeader>

                    <IonCardContent>{record.node.solucion}</IonCardContent>
                    <IonButton
                      fill="outline"
                      expand="block"
                      routerLink={`/page/tickets/comentar/${record.node.id}`}
                    >
                      {t("Comentar")}
                    </IonButton>
                  </IonCard>
                )
              )}
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default TicketsPage;
