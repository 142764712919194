import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardHeader,
  IonDatetime,
  IonCardTitle,
  IonCardContent,
  IonItem,
  IonIcon,
  IonSpinner,
  IonButton,
  IonButtons,
  IonMenuButton,
  IonLabel,
  IonSelectOption,
  IonList,
  IonTextarea,
  IonBackButton,
  IonSelect,
  IonImg,
  IonInput,
} from "@ionic/react";
import React, { useEffect, useState, useRef } from "react";
import { send as sendIcon, attach as attachIcon } from "ionicons/icons";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import AuthService from "../services/auth.service";
import { useTranslation } from "react-i18next";
import "./Pages.css";

const CREATE_INCIDENCIA = gql`
  mutation CreateTicketMutation($ticketData: TicketsInput!) {
    createTicket(ticketData: $ticketData) {
      ticket {
        id
      }
    }
  }
`;

const SINGLE_UPLOAD_MUTATION = gql`
  mutation uploadFileMutation($file: Upload!) {
    uploadFile(file: $file) {
      success
      fileName
    }
  }
`;

const GET_DATA = gql`
  query ($condominioId: Int!) {
    viewer {
      allUsuarios(filters: { condominioId: $condominioId }) {
        edges {
          node {
            id
            nombre
            paterno
            materno
          }
        }
      }
      allContactos(
        filters: { condominioId: $condominioId, tipoContactoId: 1 }
      ) {
        edges {
          node {
            id
            nombre
            paterno
            materno
          }
        }
      }
      allBitacoraIncidencias(filters: { condominioId: $condominioId }) {
        edges {
          node {
            id
          }
        }
      }
      allDepartamentos(filters: { condominioId: $condominioId }) {
        edges {
          node {
            id
            nombre
          }
        }
      }
      allIncidencias {
        edges {
          node {
            id
            nombre
          }
        }
      }
    }
  }
`;

interface PageProps {
  pageName: string | undefined;
}

const CreateTicketPage: React.FC<PageProps> = ({ pageName }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const hiddenFileInput = useRef(null);
  const [currentUser] = useState(AuthService.getCurrentUser());
  const [condominioId] = useState(
    currentUser.condominioId ? currentUser.condominioId : 0
  );
  const [image, setImage] = useState<string>();
  const [file, setFile] = useState(null);
  const [imageChange, setImageChange] = useState(false);
  const [text, setText] = useState<string>();
  const [fechaIncidencia, setFechaIncidencia] = useState<string>();
  const [asunto, setAsunto] = useState<string>();
  const [departamento, setDepartamento] = useState<string>();
  const [descripcion, setDescripcion] = useState<string>();
  const [incidencia, setIncidencia] = useState("");
  const [lang, setLang] = useState<string>(
    localStorage.getItem("langSelect")
      ? localStorage.getItem("langSelect") === "es"
        ? "es-ES"
        : "en-US"
      : "en-US"
  );

  const {
    loading: loadingData,
    error: errorData,
    data: getData,
  } = useQuery(GET_DATA, {
    variables: { condominioId },
    onCompleted(data) {},
  });

  const [
    uploadRequest,
    { data: DataUpload, loading: LoadingUpload, error: ErrorUpload },
  ] = useMutation(SINGLE_UPLOAD_MUTATION, {
    onCompleted(data) {
      const fileName = `/app-assets/uploads/${data.uploadFile.fileName}`;

      setImage(fileName);
    },
  });

  const [createTicket, { data, loading, error, reset }] = useMutation(
    CREATE_INCIDENCIA,
    {
      onError(error) {
        reset();
      },
      onCompleted() {
        history.push({
          pathname: `/page/tickets`,
          state: {
            response: "success",
          },
        });
        setImage("");
        setText("");
      },
    }
  );

  const uploadFile = async () => {
    if (!file) return;
    try {
      const res = await uploadRequest({
        variables: { file },
        //,refetchQueries: [{ query: GET_PHOTOS_QUERY }],
      });
      if (res.data) {
        setImageChange(true);
        //setFile(null);
        setTimeout(() => console.log("Succes"), 300);
      }
    } catch (err) {
      console.error(err);
    }
  };

  function setPreview(file: any) {
    setFile(file.target.files[0]);
    console.log("");
  }

  function onClickCreateComprobante() {
    createTicket({
      variables: {
        comprobanteData: {
          comentario: text,
          urlImagen: image,
        },
      },
    });
  }

  const onChangeFechaInicio = (dates: any) => {
    setFechaIncidencia(dates);
  };

  const createBitacoraVisitanteSubmit = () => {
    createTicket({
      variables: {
        ticketData: {
          asunto: asunto,
          departamentoId: departamento ? departamento : "",
          fecha: new Date(),
          estatus: "T",
          asignadoId: null,
          habitacionId: null,
          descripcion: descripcion,
          prioridad: 0,
          generaCuota: false,
          cuota: 0,
          desde: true,
        },
      },
    });
  };

  useEffect(() => {
    uploadFile();
  }, [file]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
            <IonBackButton />
          </IonButtons>
          <IonTitle>{t("new-ticket")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {loadingData ? (
          <div className="spin">
            <IonSpinner></IonSpinner>
          </div>
        ) : (
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>{t("new-ticket")}</IonCardTitle>
            </IonCardHeader>

            <IonCardContent>
              <IonList>
                <IonItem>
                  <IonLabel position="floating">{t("Asunto")}</IonLabel>
                  <IonInput
                    value={asunto}
                    onIonChange={(e) => setAsunto(e.detail.value!)}
                  ></IonInput>
                </IonItem>
                <IonItem>
                  <IonLabel position="floating">{t("Departamento")}</IonLabel>
                  <IonSelect
                    interface="popover"
                    placeholder={t("Seleccione una categoria")}
                    onIonChange={(ev) =>
                      setDepartamento(JSON.stringify(ev.detail.value))
                    }
                  >
                    {getData.viewer.allDepartamentos.edges.map(
                      (record: any) => (
                        <IonSelectOption
                          value={record.node.id}
                          key={record.node.id}
                        >
                          {record.node.nombre}
                        </IonSelectOption>
                      )
                    )}
                  </IonSelect>
                </IonItem>

                <IonItem>
                  <IonLabel position="floating">{t("contents")}</IonLabel>
                  <IonTextarea
                    value={descripcion}
                    onIonChange={(e) => setDescripcion(e.detail.value!)}
                  ></IonTextarea>
                </IonItem>
              </IonList>
            </IonCardContent>
            {LoadingUpload ? (
              <IonItem>
                <div className="spin">
                  <IonSpinner></IonSpinner>
                </div>
              </IonItem>
            ) : (
              image && (
                <IonItem>
                  <IonImg
                    style={{
                      maxWidth: "100%",
                      height: "250px",
                      width: "250px",
                    }}
                    src={`${image}`}
                  />
                </IonItem>
              )
            )}

            {loading ? (
              <div className="spin">
                <IonSpinner></IonSpinner>
              </div>
            ) : (
              <IonButton
                fill="outline"
                expand="block"
                onClick={() => {
                  createBitacoraVisitanteSubmit();
                }}
              >
                <IonIcon icon={sendIcon} slot="end"></IonIcon>
                {t("send")}
              </IonButton>
            )}
          </IonCard>
        )}
      </IonContent>
    </IonPage>
  );
};

export default CreateTicketPage;
