import { useHistory } from "react-router-dom";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSpinner,
  IonButtons,
  IonMenuButton,
  IonLabel,
  useIonViewWillEnter,
  IonCol,
  IonRow,
  IonGrid,
} from "@ionic/react";
import React, { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import "moment/locale/es";
import AuthService from "../services/auth.service";
import { useTranslation } from "react-i18next";
import "./Pages.css";

const GET_USER = gql`
  query usuario($usuarioId: String) {
    viewer {
      usuario(usuarioId: $usuarioId) {
        id
        condominio {
          habitaciones {
            edges {
              node {
                id
                nombre
                cuotas(filters: { estatusNe: "P" }) {
                  edges {
                    node {
                      id
                      residente {
                        id
                        nombre
                      }
                      formaCuota
                      monto
                      fecha
                      concepto
                      estatus
                      cuotaXMetro
                      contTipoCuota {
                        id
                        nombre
                      }
                      pagos {
                        edges {
                          node {
                            id
                            recibo
                            monto
                          }
                        }
                      }
                      recargos(filters: { estatus: "D" }) {
                        edges {
                          node {
                            id
                            deudaDe
                            deudaA
                            otrasDeudasVigentes
                            cantidad
                            cantidadTipo
                            aplicarA
                            soloMayoresA
                            conContCuotas {
                              id
                              monto
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

interface PageProps {
  pageName: string | undefined;
}

const MorososPage: React.FC<PageProps> = ({ pageName }) => {
  const { t } = useTranslation();

  const [currentUser, setCurrentUser] = useState(AuthService.getCurrentUser());
  const [condominioId, setCondominioId] = useState(
    currentUser.condominioId ? currentUser.condominioId : 0
  );

  const [cuotasPendientes, setCuotasPendientes] = useState<any[]>([]);

  const { loading, error, data, refetch } = useQuery(GET_USER, {
    variables: { usuarioId: currentUser.id },
    onCompleted(data) {
      data.viewer.usuario.condominio.habitaciones.edges.map(
        (inputHabitacion: any, indexHabitacion: any) => {
          let cuotas = [];
          let newfield: any;
          let currentContacto;
          let recargo = 0;
          let total = 0;
          let totalRecargos = 0;
          let recargosAnteriores = 0;
          let abonosAnteriores = 0;
          let hasSaldo = inputHabitacion.node.saldo ? true : false;
          let saldo = hasSaldo ? inputHabitacion.node.saldo : 0;
          //let saldoRestante = saldo;
          let saldoRestante = 0;
          let pagoPorCuota = 0;
          let cantidadMorosa = 0;

          inputHabitacion.node.cuotas.edges.map((input: any, index: any) => {
            //recargo = 0;
            totalRecargos = 0;
            recargosAnteriores = 0;
            abonosAnteriores = 0;
            total = 0;
            recargo = 0;
            //saldo = 0;
            pagoPorCuota = 0;

            input.node.recargos.edges.map((value: any, index: any) => {
              recargosAnteriores += value.node.cantidad;
            });

            input.node.pagos.edges.map((value: any, index: any) => {
              abonosAnteriores += value.node.monto;
            });

            total +=
              recargo +
              input.node.monto +
              recargosAnteriores -
              (abonosAnteriores + saldoRestante);

            cantidadMorosa += total;
          });

          setCuotasPendientes((current) => [
            ...current,
            {
              habitacionId: inputHabitacion.node.id,
              habitacion: inputHabitacion.node.nombre,
              pago: cantidadMorosa,
              descuento: 0,
              hasDiscount: false,
              discountTitle: "",
              abono: abonosAnteriores,
            },
          ]);
        }
      );
    },
    onError(error) {
      //reset();
    },
  });

  useIonViewWillEnter(() => {
    refetch();
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{t("debtors")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {loading ? (
          <div className="spin">
            <IonSpinner></IonSpinner>
          </div>
        ) : (
          <>
            <IonGrid fixed={true}>
              <IonRow>
                <IonCol>{t("unit")}</IonCol>
                <IonCol>{t("outstanding-balance")}</IonCol>
              </IonRow>
              {cuotasPendientes.map((input: any, index: any) => (
                <IonRow key={index}>
                  <IonCol>{input.habitacion}</IonCol>
                  <IonCol>
                    <IonLabel color="danger">
                      {new Intl.NumberFormat("es-MX", {
                        style: "currency",
                        currency: "MXN",
                      }).format(input.pago)}{" "}
                      MXN
                    </IonLabel>
                  </IonCol>
                </IonRow>
              ))}
            </IonGrid>
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default MorososPage;
