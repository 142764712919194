import {
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";
import { business as businessIcon, time as timeIcon } from "ionicons/icons";
import React from "react";
import { Redirect, Route } from "react-router-dom";
import AreasComunesTab from "./areasComunes/AreasComunesTab";
import ReservacionesTab from "./areasComunes/ReservacionesTab";
import { useTranslation } from "react-i18next";

const AreasComunesTabsPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <IonTabs>
      <IonRouterOutlet>
        <Route exact path="/page/areascomunes/areascomunes">
          <AreasComunesTab />
        </Route>
        <Route exact path="/page/areascomunes/reservaciones">
          <ReservacionesTab />
        </Route>
        <Route exact path="/page/areascomunes">
          <Redirect to="/page/areascomunes/areascomunes" />
        </Route>
      </IonRouterOutlet>
      <IonTabBar slot="bottom">
        <IonTabButton tab="tab1" href="/page/areascomunes/areascomunes">
          <IonIcon icon={businessIcon} />
          <IonLabel>{t("amenities")}</IonLabel>
        </IonTabButton>
        <IonTabButton tab="tab2" href="/page/areascomunes/reservaciones">
          <IonIcon icon={timeIcon} />
          <IonLabel>{t("my-bookings")}</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default AreasComunesTabsPage;
