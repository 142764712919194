import { useFormInput } from "./utils";

export const useSignupFields = () => {
  return [
    {
      id: "name",
      label: "Name",
      required: true,
      input: {
        props: {
          type: "text",
          placeholder: "Joe Bloggs",
        },
        state: useFormInput(""),
      },
    },
    {
      id: "email",
      label: "Email",
      required: true,
      input: {
        props: {
          type: "email",
          placeholder: "joe@bloggs.com",
        },
        state: useFormInput(""),
      },
    },
    {
      id: "password",
      label: "Password",
      required: true,
      input: {
        props: {
          type: "password",
          placeholder: "*********",
        },
        state: useFormInput(""),
      },
    },
  ];
};

export const useLoginFields = () => {
  return [
    {
      id: "email",
      label: "Correo Electrónico",
      required: true,
      input: {
        props: {
          type: "email",
          placeholder: "usuario@ejemplo.com",
        },
        state: useFormInput(""),
      },
    },
    {
      id: "password",
      label: "Contraseña",
      required: true,
      input: {
        props: {
          type: "password",
          placeholder: "*******",
        },
        state: useFormInput(""),
      },
    },
  ];
};
