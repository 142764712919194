import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonItem,
  IonIcon,
  IonLabel,
  IonButton,
  IonButtons,
  IonMenuButton,
  IonGrid,
  IonBackButton,
  IonSpinner,
  IonDatetime,
  IonRow,
  IonCol,
  useIonViewWillLeave,
  IonList,
  IonInput,
  IonSelectOption,
  IonSelect,
  IonDatetimeButton,
  IonModal,
  IonCheckbox,
} from "@ionic/react";
import React, { useState } from "react";
import { calendar as calendarIcon } from "ionicons/icons";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useParams, useHistory } from "react-router";
import "./Tab1.css";
import { useTranslation } from "react-i18next";
import AuthService from "../../services/auth.service";

const GET_AREA_COMUN = gql`
  query bitacoraVisitante($bitacoraVisitanteId: String, $condominioId: Int!) {
    viewer {
      bitacoraVisitante(bitacoraVisitanteId: $bitacoraVisitanteId) {
        id
        visitanteId
        visitante {
          id
          nombre
          vehiculo
        }
        fechaVisita
        fechaSalida
        fechaRegistro
        personas
        nota
        vehiculo
        residente {
          id
          nombre
          paterno
          materno
        }
        usuario {
          nombre
          materno
          paterno
        }
        condominio {
          id
          empresa
        }
      }
      allUsuarios(filters: { condominioId: $condominioId }) {
        edges {
          node {
            id
            nombre
            paterno
            materno
          }
        }
      }
      allContactos(
        filters: { condominioId: $condominioId, tipoContactoId: 1 }
      ) {
        edges {
          node {
            id
            nombre
            paterno
            materno
          }
        }
      }
      allVisitantes(filters: { condominioId: $condominioId }) {
        edges {
          node {
            id
            nombre
            vehiculo
          }
        }
      }
    }
  }
`;

const GET_BITACORA_VISITANTE_CREATE = gql`
  query bitacoraVisitante($condominioId: Int!) {
    viewer {
      allUsuarios(filters: { condominioId: $condominioId }) {
        edges {
          node {
            id
            nombre
            paterno
            materno
          }
        }
      }
      allContactos(
        filters: { condominioId: $condominioId, tipoContactoId: 1 }
      ) {
        edges {
          node {
            id
            nombre
            paterno
            materno
          }
        }
      }
      allVisitantes(filters: { condominioId: $condominioId }) {
        edges {
          node {
            id
            nombre
            vehiculo
          }
        }
      }
    }
  }
`;

const UPDATE_BITACORAVISITANTE = gql`
  mutation UpdateBitacoraVisitanteMutation(
    $bitacoravisitanteData: BitacoraVisitantesInput!
  ) {
    updateBitacoraVisitante(bitacoravisitanteData: $bitacoravisitanteData) {
      bitacoravisitante {
        id
      }
    }
  }
`;

const CREATE_BITACORAVISITANTE = gql`
  mutation CreateBitacoraVisitanteMutation(
    $bitacoravisitanteData: BitacoraVisitantesInput!
  ) {
    createBitacoraVisitante(bitacoravisitanteData: $bitacoravisitanteData) {
      bitacoravisitante {
        id
      }
    }
  }
`;

interface PageProps {
  pageName: string | undefined;
}

const VisitantesPageEdit: React.FC<PageProps> = ({ pageName }) => {
  const { t } = useTranslation();
  const { bitacoraVisitanteId } = useParams<{ bitacoraVisitanteId: string }>();
  const [showReservaForm, setShowReservaForm] = useState(false);
  const currentUser = AuthService.getCurrentUser();
  const usuarioId = currentUser.id;
  const condominioId = currentUser.condominioId ? currentUser.condominioId : 0;
  const [fechaSalida, setFechaSalida] = useState(formatDate(new Date()));
  const history = useHistory();
  const [personas, setPersonas] = useState("");
  const [vehiculo, setVehiculo] = useState("");
  const [nota, setNota] = useState("");
  const [fechaVisita, setFechaVisita] = useState(formatDate(new Date()));
  const [fechaRegistro, setFechaRegistro] = useState(formatDate(new Date()));
  const [visitante, setVisitante] = useState("");
  const [isNewVisitante, setIsNewVisitante] = useState(false);
  const [visitanteOptions, setVisitanteOptions] = useState<
    { value: string | number; label: string }[]
  >([]);

  function formatDate(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }

  const { loading, data } = useQuery(
    bitacoraVisitanteId === "create"
      ? GET_BITACORA_VISITANTE_CREATE
      : GET_AREA_COMUN,
    {
      variables: { bitacoraVisitanteId, condominioId },
      onCompleted(data) {
        const visitantes = [...data.viewer.allVisitantes.edges].map((o) => ({
          value: o.node.id,
          label: `${o.node.nombre}`,
        }));

        setVisitanteOptions(visitantes);
        setVisitante(data.viewer.bitacoraVisitante.visitante.id);
        setPersonas(data.viewer.bitacoraVisitante.personas);
        setVehiculo(data.viewer.bitacoraVisitante.vehiculo);
        setNota(data.viewer.bitacoraVisitante.nota);
        setFechaVisita(
          formatDate(new Date(data.viewer.bitacoraVisitante.fechaVisita))
        );
        setFechaSalida(
          formatDate(new Date(data.viewer.bitacoraVisitante.fechaSalida))
        );
        setFechaRegistro(
          formatDate(new Date(data.viewer.bitacoraVisitante.fechaRegistro))
        );
      },
      onError(error) {
        //reset();
      },
    }
  );

  const [updateBitacoraVisitante, { loading: loadingReservacion, reset }] =
    useMutation(UPDATE_BITACORAVISITANTE, {
      onError(error) {
        reset();
      },
      onCompleted() {
        history.push({
          pathname: `/page/visitantes`,
        });
      },
    });

  const [createBitacoraVisitante] = useMutation(CREATE_BITACORAVISITANTE, {
    onError(error) {
      reset();
    },
    onCompleted() {
      history.push({
        pathname: `/page/visitantes`,
      });
    },
  });

  const onChangedFechaRegistro = (date: any) => {
    setFechaRegistro(date);
  };

  const onChangedFechaSalida = (date: any) => {
    setFechaSalida(date);
  };

  const onChangedFechaVisita = (date: any) => {
    setFechaVisita(date);
  };

  const handleIsNewVisitante = (checked: boolean) => {
    setIsNewVisitante(checked);
    if (checked) setVisitante("");
  };

  useIonViewWillLeave(() => {
    setShowReservaForm(false);
  });

  const handleClickGuardarCambios = () => {
    if (bitacoraVisitanteId === "create") {
      createBitacoraVisitante({
        variables: {
          bitacoravisitanteData: {
            residenteId: usuarioId,
            visitanteId: visitante ? visitante : null,
            personas: personas,
            fechaVisita: fechaVisita,
            fechaSalida: fechaSalida,
            vehiculo: vehiculo,
            nota: nota,
            isNewVisitante: isNewVisitante,
          },
        },
      });
    } else {
      updateBitacoraVisitante({
        variables: {
          bitacoravisitanteData: {
            id: bitacoraVisitanteId,
            residenteId: usuarioId,
            visitanteId: visitante ? visitante : null,
            personas: personas,
            fechaVisita: fechaVisita,
            fechaSalida: fechaSalida,
            vehiculo: vehiculo,
            nota: nota,
            isNewVisitante: isNewVisitante,
          },
        },
      });
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
            <IonBackButton />
          </IonButtons>
          {loading ? (
            <IonSpinner name="dots"></IonSpinner>
          ) : (
            <IonTitle>
              {data && data.viewer.bitacoraVisitante
                ? `${visitante} [${t("visitor")}] ${t("edit")}`
                : `Create ${t("visitor")}`}
            </IonTitle>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid fixed={true}>
          {loading ? (
            <div className="spin">
              <IonSpinner></IonSpinner>
            </div>
          ) : !showReservaForm ? (
            <>
              <form>
                <IonList>
                  <IonRow>
                    <IonCol
                      size="6"
                      size-sm="6"
                      size-md="4"
                      size-lg="3"
                      size-xl="3"
                    >
                      <IonItem>
                        <IonCheckbox
                          value={isNewVisitante}
                          onIonChange={(e) =>
                            handleIsNewVisitante(e.detail.checked)
                          }
                          labelPlacement="end"
                          justify="start"
                        >
                          Nuevo visitante
                        </IonCheckbox>
                      </IonItem>
                    </IonCol>

                    <IonCol
                      size="6"
                      size-sm="6"
                      size-md="8"
                      size-lg="9"
                      size-xl="9"
                    >
                      {isNewVisitante ? (
                        <IonItem>
                          <IonInput
                            placeholder="Introduce el nombre del visitante"
                            value={visitante}
                            onIonChange={(e) => setVisitante(e.detail.value!)}
                            clearInput={true}
                          />
                        </IonItem>
                      ) : (
                        <IonItem>
                          <IonLabel>Visitante:</IonLabel>
                          <IonSelect
                            placeholder="Seleccione un visitante"
                            value={visitante}
                            interface="popover"
                            onIonChange={(e) => setVisitante(e.detail.value)}
                          >
                            {visitanteOptions.map((record: any) => (
                              <IonSelectOption
                                value={record.value}
                                key={record.value}
                              >
                                {record.label}
                              </IonSelectOption>
                            ))}
                          </IonSelect>
                        </IonItem>
                      )}
                    </IonCol>
                  </IonRow>
                  <IonItem>
                    <IonInput
                      label="Número de personas"
                      type="number"
                      labelPlacement="stacked"
                      clearInput={true}
                      placeholder="Enter número de personas"
                      value={personas}
                      onIonChange={(e) => setPersonas(e.detail.value!)}
                    ></IonInput>
                  </IonItem>
                  <IonItem>
                    <IonInput
                      label="Nota"
                      labelPlacement="stacked"
                      clearInput={true}
                      placeholder="Enter Nota"
                      value={nota}
                      onIonChange={(e) => setNota(e.detail.value!)}
                    ></IonInput>
                  </IonItem>
                  <IonRow>
                    <IonCol
                      size="12"
                      size-sm="12"
                      size-md="6"
                      size-lg="6"
                      size-xl="6"
                    >
                      <IonItem>
                        <IonLabel>Fecha de Visita</IonLabel>
                        <IonDatetimeButton datetime="fechaVisita"></IonDatetimeButton>
                        <IonModal keepContentsMounted={true}>
                          <IonDatetime
                            id="fechaVisita"
                            presentation="date-time"
                            locale="es-ES"
                            value={fechaVisita}
                            onIonChange={(event) =>
                              onChangedFechaVisita(event.detail.value)
                            }
                          >
                            <span slot="title">Fecha de visita:</span>
                          </IonDatetime>
                        </IonModal>
                      </IonItem>
                    </IonCol>
                    <IonCol
                      size="12"
                      size-sm="12"
                      size-md="6"
                      size-lg="6"
                      size-xl="6"
                    >
                      <IonItem>
                        <IonLabel>Fecha de Salida</IonLabel>
                        <IonDatetimeButton datetime="fechaSalida"></IonDatetimeButton>
                        <IonModal keepContentsMounted={true}>
                          <IonDatetime
                            id="fechaSalida"
                            presentation="date-time"
                            locale="es-ES"
                            value={fechaSalida}
                            onIonChange={(event) =>
                              onChangedFechaSalida(event.detail.value)
                            }
                          >
                            <span slot="title">Fecha de salida:</span>
                          </IonDatetime>
                        </IonModal>
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol
                      size="12"
                      size-sm="12"
                      size-md="6"
                      size-lg="6"
                      size-xl="6"
                    >
                      <IonItem>
                        <IonInput
                          label="Vehículo"
                          labelPlacement="stacked"
                          clearInput={true}
                          placeholder="Enter Vehículo"
                          value={vehiculo}
                          onIonChange={(e) => setVehiculo(e.detail.value!)}
                        ></IonInput>
                      </IonItem>
                    </IonCol>
                    <IonCol
                      size="12"
                      size-sm="12"
                      size-md="6"
                      size-lg="6"
                      size-xl="6"
                    >
                      <IonItem>
                        <IonLabel>Fecha de registro</IonLabel>
                        <IonDatetimeButton datetime="fechaRegistro"></IonDatetimeButton>
                        <IonModal keepContentsMounted={true}>
                          <IonDatetime
                            id="fechaRegistro"
                            presentation="date-time"
                            locale="es-ES"
                            value={fechaRegistro}
                            onIonChange={(event) =>
                              onChangedFechaRegistro(event.detail.value)
                            }
                          >
                            <span slot="title">Fecha de registro:</span>
                          </IonDatetime>
                        </IonModal>
                      </IonItem>
                    </IonCol>
                  </IonRow>
                </IonList>
                {loadingReservacion ? (
                  <div className="spin">
                    <IonSpinner></IonSpinner>
                  </div>
                ) : (
                  <IonButton
                    className="custom-button"
                    expand="block"
                    fill="outline"
                    onClick={handleClickGuardarCambios}
                  >
                    <IonIcon icon={calendarIcon} slot="start" />
                    Guardar cambios
                  </IonButton>
                )}
              </form>
            </>
          ) : (
            <IonCard key={3}></IonCard>
          )}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default VisitantesPageEdit;
