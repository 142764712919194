import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonRow,
  IonCol,
  IonGrid,
  IonButtons,
  IonMenuButton,
  IonSpinner,
  IonButton,
  IonIcon,
  IonRefresher,
  IonRefresherContent,
} from "@ionic/react";
import { print as printIcon, refresh as refreshIcon } from "ionicons/icons";
import "./Tab1.css";
import { gql, useLazyQuery } from "@apollo/client";
import AuthService from "../../services/auth.service";
import Moment from "moment";
import { RefresherEventDetail } from "@ionic/core";
import { useTranslation, withTranslation } from "react-i18next";

const GET_USER = gql`
  query usuario($usuarioId: String) {
    viewer {
      usuario(usuarioId: $usuarioId) {
        id
        comprobantes {
          edges {
            node {
              id
              comentario
              urlImagen
              fecha
              pagoId
              estatus
            }
          }
        }
        habitaResidente {
          id
          habitacion {
            id
            nombre
            superficie
            cuota
            referenciaBancaria
            saldo
            habitaResidente {
              residente {
                id
                nombre
                paterno
                materno
                email
              }
            }
            tipoVivienda {
              id
              nombre
            }
            condominio {
              id
              empresa
            }
            torre {
              id
              nombre
              condominio {
                id
                empresa
              }
            }
            cuotas {
              edges {
                node {
                  id
                  residente {
                    id
                    nombre
                  }
                  formaCuota
                  monto
                  fecha
                  concepto
                  estatus
                  cuotaXMetro
                  contTipoCuota {
                    id
                    nombre
                  }
                  pagos {
                    edges {
                      node {
                        id
                        recibo
                        monto
                        fechaPago
                        formaPagoId
                      }
                    }
                  }
                  recargos(filters: { estatus: "D" }) {
                    edges {
                      node {
                        id
                        deudaDe
                        deudaA
                        otrasDeudasVigentes
                        cantidad
                        cantidadTipo
                        aplicarA
                        soloMayoresA
                        conContCuotas {
                          id
                          monto
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const pagoCompletoStyle = {
  backgroundColor: "rgba(60, 179, 113, 0.2)",
};

const MisPagosTab: React.FC = () => {
  const { t } = useTranslation();
  const currentUser = AuthService.getCurrentUser();

  const [pagosIndividuales, setPagosIndividuales] = useState<any[]>([]);
  const [totalPagado, setTotalPagado] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const [loadMisPagos] = useLazyQuery(GET_USER, {
    fetchPolicy: "cache-and-network",
    variables: { usuarioId: currentUser.id },
    onCompleted(data) {
      setIsLoading(false);
      setPagosIndividuales([]);
      let total = 0;
      data.viewer.usuario.habitaResidente.habitacion.cuotas.edges.forEach(
        (input: any) => {
          input.node.pagos.edges.forEach((value: any) => {
            const pagoObject = {
              id: value.node.id,
              fecha: value.node.fechaPago,
              recibo: value.node.recibo,
              monto: value.node.monto,
              metodo: value.node.formaPagoId,
            };

            total += value.node.monto;
            setPagosIndividuales((current) => current.concat(pagoObject));
            setTotalPagado(total);
          });
        }
      );

      //reset();
    },
    onError(error) {
      //reset();
    },
  });

  const onRefreshClick = () => {
    setIsLoading(true);
    loadMisPagos();
  };

  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    console.log("Begin async operation");
    event.detail.complete();
    setIsLoading(true);
    loadMisPagos();
  }

  useEffect(() => {
    loadMisPagos();
  }, [loadMisPagos]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle size="large">
            {t("my-payments")} [{t("my-finances")}]
          </IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onRefreshClick}>
              <IonIcon slot="icon-only" icon={refreshIcon}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonRefresher
          slot="fixed"
          onIonRefresh={doRefresh}
          pullFactor={0.5}
          pullMin={100}
          pullMax={200}
        >
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <IonGrid fixed={true}>
          {isLoading ? (
            <div className="spin">
              <IonSpinner></IonSpinner>
            </div>
          ) : (
            <>
              <IonRow>
                <IonCol size="2">{t("date")}</IonCol>
                <IonCol size="2">{t("invoice-number")}</IonCol>
                <IonCol size="2">{t("payment-method")}</IonCol>
                <IonCol size="3">{t("amount")}</IonCol>
                <IonCol size="3">{t("print")}</IonCol>
              </IonRow>
              {pagosIndividuales.map((input: any, index: any) => (
                <IonRow key={index}>
                  <IonCol size="2">
                    {Moment(input.fecha).format("DD-MMM-YYYY")}
                  </IonCol>
                  <IonCol size="2">{input.recibo}</IonCol>
                  <IonCol size="2">{input.metodo}</IonCol>
                  <IonCol size="3">
                    {new Intl.NumberFormat("es-MX", {
                      style: "currency",
                      currency: "MXN",
                    }).format(input.monto)}
                  </IonCol>
                  <IonCol size="3">
                    <IonButton fill="outline" expand="block">
                      <IonIcon icon={printIcon} />
                    </IonButton>
                  </IonCol>
                </IonRow>
              ))}
              <IonRow>
                <IonCol size="9" style={pagoCompletoStyle}>
                  {t("total")}
                </IonCol>
                <IonCol size="3" style={pagoCompletoStyle}>
                  {new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                  }).format(totalPagado)}
                </IonCol>
              </IonRow>
            </>
          )}
        </IonGrid>
        {!isLoading && (
          <p style={{ textAlign: "center" }}>{t("legal-information")}</p>
        )}
      </IonContent>
    </IonPage>
  );
};

export default withTranslation()(MisPagosTab);
