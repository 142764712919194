import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCardTitle,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonPage,
  IonRouterLink,
  IonRow,
  IonToolbar,
  IonNote,
  IonAvatar,
  IonListHeader,
  IonLabel,
  IonList,
  IonInput,
  IonItem,
  IonImg,
  IonSpinner,
} from "@ionic/react";
import styles from "./Login.module.scss";

import {
  logIn as logInIcon,
  eye as eyeIcon,
  eyeOff as eyeOffIcon,
} from "ionicons/icons";

import { useLoginFields } from "../data/fields";

import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { validateForm } from "../data/utils";
import { useParams } from "react-router";
import { gql, useMutation } from "@apollo/client";
import AuthService from "../services/auth.service";
import { useHistory } from "react-router-dom";

const LOGIN_MUTATION = gql`
  mutation Login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      user {
        id
        username
        id
        nombre
        paterno
        materno
        email
        usuario
        estatus
        sexo
        createdAt
        condominioId
        condominio {
          id
          empresa
        }
        categorias {
          edges {
            node {
              id
              nombre
            }
          }
        }
        roles {
          edges {
            node {
              id
              name
            }
          }
        }
      }
      authToken
    }
  }
`;

const Login = (props) => {
  const { loggedIn } = AuthService.loggedIn();
  const [isLoggenIn, setIsLoggenIn] = useState(loggedIn ? true : false);

  const history = useHistory();

  const params = useParams();

  const fields = useLoginFields();
  const [errors, setErrors] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const router = document.querySelector("ion-router");
  const routeRedirect = document.createElement("ion-route-redirect");
  routeRedirect.setAttribute("from", "/login");
  routeRedirect.setAttribute("to", "/page/anuncios");

  const login2 = () => {
    const errors = validateForm(fields);
    setErrors(errors);

    console.log(fields);

    if (!errors.length) {
      //  Submit your form here
    }
  };

  const [login, { loading, error, reset }] = useMutation(LOGIN_MUTATION, {
    onCompleted({ login }) {
      if (login) {
        const roles = [...login.user.roles.edges].map(
          (o) => `ROLE_${o.node.name.toUpperCase()}`
        );
        const categorias = [...login.user.categorias.edges].map(
          (o) => `CATEGORIA_${o.node.nombre.toUpperCase()}`
        );
        login.user.roles = roles;
        login.user.categorias = categorias;
        login.user.condominioId = login.user.condominioId;
        login.user.condominio = login.user.condominio;
        localStorage.setItem("user", JSON.stringify(login.user));
        localStorage.setItem("authToken", login.authToken);
        //history.Redirect("/page/anuncios");
        console.log(login);
        setTimeout(() => {
          //document.body.classList.remove('login-bg');
          history.push("/page/anuncios");
          window.location.reload();
        }, 300);

        //window.location.reload();
        //router.appendChild(routeRedirect);
        //setIsLoggenIn(true);
      }
    },
    onError(error) {
      reset();
    },

    variables: {
      username: email,
      password: password,
    },
  });

  if (loggedIn) {
    return <Redirect to="/page/anuncios" />;
  }

  return (
    <IonPage className={styles.loginPage}>
      <IonContent fullscreen>
        <IonGrid className="ion-padding">
          <IonRow>
            <IonCol style={{ border: "0px" }}>
              <div className="center">
                <IonImg
                  style={{
                    width: "20%",
                    margin: "auto",
                    display: "block",
                    minWidth: "200px",
                  }}
                  alt="Silhouette of a person's head"
                  src="https://admin.vittapp.com/app-assets/images/logo/logo.png"
                />
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol
              size="12"
              className={styles.headingText}
              style={{ border: "0px" }}
            >
              <div className="ion-text-center">
                <IonCardTitle>VittApp</IonCardTitle>

                <h5>Bienvenido</h5>
              </div>
            </IonCol>
          </IonRow>

          <IonRow className="ion-margin-top ion-padding-top">
            <IonCol size="12" style={{ border: "0px" }}>
              <IonList>
                <IonItem>
                  <IonLabel position="stacked">Correo Electrónico</IonLabel>
                  <IonInput
                    type="email"
                    value={email}
                    onIonChange={(event) => setEmail(event.detail.value)}
                  />
                </IonItem>
                <IonItem>
                  <IonLabel position="stacked">Contraseña</IonLabel>
                  <IonInput
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onIonChange={(event) => setPassword(event.detail.value)}
                  />
                  <IonIcon
                    onClick={(event) => setShowPassword(!showPassword)}
                    icon={showPassword ? eyeIcon : eyeOffIcon}
                    slot="end"
                  />
                </IonItem>
              </IonList>

              {loading ? (
                <div className="spin">
                  <IonSpinner></IonSpinner>
                </div>
              ) : (
                <IonButton
                  className="custom-button"
                  expand="block"
                  fill="outline"
                  onClick={login}
                >
                  <IonIcon icon={logInIcon} slot="end" />
                  Iniciar sesión
                </IonButton>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>

      {/*<IonFooter>
				<IonGrid className="ion-no-margin ion-no-padding">

                    <Action message="Don't have an account?" text="Sign up" link="/signup" />
                    <Wave />
				</IonGrid>
                        </IonFooter>*/}
    </IonPage>
  );
};

export default Login;
