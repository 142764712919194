import React from "react";
import fallback from "../images/logo.png";

// Define the props type
interface ImageWithFallbackProps {
  src: string;
  alt: string;
  fallBackSrc?: string;
}

const ImageWithFallback: React.FC<ImageWithFallbackProps> = ({ src, alt, fallBackSrc = fallback }) => {
  return (
      <img
        src={src}
        alt={alt}
        style={{ maxWidth: "100%", height: "186px", width: "100%" }}
        onError={(e) => (e.currentTarget.src = fallBackSrc)}
      />
  );
};

export default ImageWithFallback;