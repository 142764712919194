import {
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";
import {
  home as homeIcon,
  settings as settingsIcon,
  cash as cashIcon,
  square,
  triangle,
} from "ionicons/icons";
import React from "react";
import { Redirect, Route } from "react-router-dom";
//import { useAuth } from './auth';
import Tab1 from "./pages/Tab1";
import Tab2 from "./pages/Tab2";
import Tab3 from "./pages/Tab3";
import Home from "./pages/Home";
import TabPage from "./pages/TabPage";
const AppTabs: React.FC = () => {
  //const { loggedIn } = useAuth();
  //if (!loggedIn) {
  //  return <Redirect to="/login" />;
  //}
  return (
    <IonTabs>
      <IonRouterOutlet>
        <Route
          exact
          path="/home/tab1"
          render={(props) => <TabPage pageName="Contact" {...props} />}
        >
          <Tab1 />
        </Route>
        <Route exact path="/home/tab2">
          <Tab2 />
        </Route>
        <Route path="/home/tab3">
          <Tab3 />
        </Route>
        <Route exact path="/home">
          <Redirect to="/home/tab1" />
        </Route>
      </IonRouterOutlet>
      <IonTabBar slot="bottom">
        <IonTabButton tab="tab1" href="/home/tab1">
          <IonIcon icon={homeIcon} />
          <IonLabel>Inicio</IonLabel>
        </IonTabButton>
        <IonTabButton tab="tab2" href="/home/tab2">
          <IonIcon icon={cashIcon} />
          <IonLabel>Finanzas</IonLabel>
        </IonTabButton>
        <IonTabButton tab="tab3" href="/home/tab3">
          <IonIcon icon={settingsIcon} />
          <IonLabel>Cuenta</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default AppTabs;
