import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonItem,
  IonIcon,
  IonLabel,
  IonButton,
  IonButtons,
  IonMenuButton,
  IonGrid,
  IonBackButton,
  IonSpinner,
  IonDatetime,
  IonRow,
  IonCol,
  useIonViewWillLeave,
  IonList,
  IonInput,
  IonSelectOption,
  IonSelect,
  IonTextarea,
} from "@ionic/react";
import React, { useState } from "react";
import { calendar as calendarIcon } from "ionicons/icons";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useParams, useHistory } from "react-router";
import "./Tab1.css";
import { useTranslation } from "react-i18next";
import AuthService from "../../services/auth.service";

const GET_AREA_COMUN = gql`
  query airbnbReservasion($airbnbReservaId: String) {
    viewer {
      airbnbReserva(airbnbReservaId: $airbnbReservaId) {
        id
        condominio {
          id
        }
        habitaResidente {
          id
          nombre
          habitaResidente {
            habitacion {
              id
              nombre
            }
          }
        }
        fechaLlegada
        fechaSalida
        nombre
        paterno
        materno
        urlDocumento
        acompanantes
        comentariosCondominio
        comentarios
        fechaRegistro
        estatus
        usuarioId
      }
    }
  }
`;

const UPDATE_AIRBNB_RESERVA = gql`
  mutation UpdateAirbnbReserva($airbnbreservaData: AirbnbReservaInput!) {
    updateAirbnbReserva(airbnbreservaData: $airbnbreservaData) {
      airbnbreserva {
        id
        condominioId
        habitaResidenteId
        fechaLlegada
        fechaSalida
        nombre
        paterno
        materno
        urlDocumento
        acompanantes
        comentarios
        fechaRegistro
        fechaModificacion
        estatus
        comentariosCondominio
        usuarioId
      }
    }
  }
`;

const CREATE_AIRBNB_RESERVA = gql`
  mutation CreateAirbnbReserva($airbnbreservaData: AirbnbReservaInput!) {
    createAirbnbReserva(airbnbreservaData: $airbnbreservaData) {
      airbnbreserva {
        id
      }
    }
  }
`;

interface PageProps {
  pageName: string | undefined;
}

const AirbnbPage: React.FC<PageProps> = ({ pageName }) => {
  const { t } = useTranslation();
  const { airbnbReservaId } = useParams<{ airbnbReservaId: string }>();
  const [showReservaForm, setShowReservaForm] = useState(false);
  const [currentUser, setCurrentUser] = useState(AuthService.getCurrentUser());
  const usuarioId = currentUser.id;

  const [nombre, setNombre] = useState("");
  const [apellidoPaterno, setApellidoPaterno] = useState("");
  const [apellidoMaterno, setApellidoMaterno] = useState("");
  const [fechaLlegada, setFechaLlegada] = useState(formatDate(new Date()));
  const [fechaSalida, setFechaSalida] = useState(formatDate(new Date()));
  const [estatusReservacion, setEstatusReservacion] = useState("");
  const [acompanantes, setAcompanantes] = useState("");
  const [notaResidente, setNotaResidente] = useState("");
  const [notaCondominio, setNotaCondominio] = useState("");
  const history = useHistory();

  function formatDate(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }

  const { loading, data } = useQuery(GET_AREA_COMUN, {
    variables: { airbnbReservaId },
    onCompleted(data) {
      setNombre(data.viewer.airbnbReserva.nombre);
      setApellidoPaterno(data.viewer.airbnbReserva.paterno);
      setApellidoMaterno(data.viewer.airbnbReserva.materno);
      setFechaLlegada(data.viewer.airbnbReserva.fechaLlegada);
      setFechaSalida(data.viewer.airbnbReserva.fechaSalida);
      setEstatusReservacion(data.viewer.airbnbReserva.estatus);
      setAcompanantes(data.viewer.airbnbReserva.acompanantes);
      setNotaResidente(data.viewer.airbnbReserva.comentarios);
      setNotaCondominio(data.viewer.airbnbReserva.comentariosCondominio);
    },
    onError(error) {
      //reset();
    },
  });

  const [updateAirbnbReserva, { loading: loadingReservacion, reset }] =
    useMutation(UPDATE_AIRBNB_RESERVA, {
      onError(error) {
        reset();
      },
      onCompleted() {
        history.push({
          pathname: `/page/airbnb/reservaciones`,
        });
      },
    });

  const [createAirbnbReserva] = useMutation(CREATE_AIRBNB_RESERVA, {
    onError(error) {
      reset();
    },
    onCompleted() {
      history.push({
        pathname: `/page/airbnb/reservaciones`,
      });
    },
  });

  const onChangedFechaLlegada = (date: any) => {
    setFechaLlegada(date);
  };

  const onChangedFechaSalida = (date: any) => {
    setFechaSalida(date);
  };

  useIonViewWillLeave(() => {
    setShowReservaForm(false);
  });

  const reservationStatusOptions = [
    { value: "R", label: "Reservado" },
    { value: "E", label: "Entrada" },
    { value: "S", label: "Salida" },
    { value: "C", label: "Cancelada" },
  ];

  const handleClickGuardarCambios = () => {
    if (airbnbReservaId === "create") {
      createAirbnbReserva({
        variables: {
          airbnbreservaData: {
            habitaResidenteId: usuarioId,
            nombre: nombre,
            paterno: apellidoPaterno,
            materno: apellidoMaterno,
            fechaLlegada: fechaLlegada,
            fechaSalida: fechaSalida,
            estatus: estatusReservacion,
            acompanantes: acompanantes,
            comentarios: notaResidente,
            comentariosCondominio: notaCondominio,
          },
        },
      });
    } else {
      updateAirbnbReserva({
        variables: {
          airbnbreservaData: {
            id: airbnbReservaId,
            nombre: nombre,
            paterno: apellidoPaterno,
            materno: apellidoMaterno,
            fechaLlegada: fechaLlegada,
            fechaSalida: fechaSalida,
            estatus: estatusReservacion,
            acompanantes: acompanantes,
            comentarios: notaResidente,
            comentariosCondominio: notaCondominio,
          },
        },
      });
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
            <IonBackButton />
          </IonButtons>
          {loading ? (
            <IonSpinner name="dots"></IonSpinner>
          ) : (
            <IonTitle>
              {data && data.viewer.airbnbReserva
                ? `${data.viewer.airbnbReserva.nombre} [${t("airbnb")}] ${t(
                    "edit"
                  )}`
                : `Create ${t("airbnb")}`}
            </IonTitle>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid fixed={true}>
          {loading ? (
            <div className="spin">
              <IonSpinner></IonSpinner>
            </div>
          ) : !showReservaForm ? (
            <>
              <form>
                <IonList>
                  <IonItem>
                    <IonInput
                      label="Nombre"
                      labelPlacement="stacked"
                      clearInput={true}
                      placeholder="Enter name"
                      value={nombre}
                      onIonChange={(e) => setNombre(e.detail.value!)}
                    ></IonInput>
                  </IonItem>
                  <IonItem>
                    <IonInput
                      label="Apellido Paterno"
                      labelPlacement="stacked"
                      clearInput={true}
                      placeholder="Enter Apellido Paterno"
                      value={apellidoPaterno}
                      onIonChange={(e) => setApellidoPaterno(e.detail.value!)}
                    ></IonInput>
                  </IonItem>
                  <IonItem>
                    <IonInput
                      label="Apellido Materno"
                      labelPlacement="stacked"
                      clearInput={true}
                      placeholder="Enter Apellido Materno"
                      value={apellidoMaterno}
                      onIonChange={(e) => setApellidoMaterno(e.detail.value!)}
                    ></IonInput>
                  </IonItem>
                  <IonRow>
                    <IonCol>
                      <IonDatetime
                        size="cover"
                        locale="es-ES"
                        presentation="date-time"
                        value={fechaLlegada}
                        onIonChange={(event) =>
                          onChangedFechaLlegada(event.detail.value)
                        }
                      >
                        <span slot="title">Fecha de llegada:</span>
                      </IonDatetime>
                    </IonCol>
                    <IonCol>
                      <IonDatetime
                        size="cover"
                        locale="es-ES"
                        presentation="date-time"
                        value={fechaSalida}
                        onIonChange={(event) =>
                          onChangedFechaSalida(event.detail.value)
                        }
                      >
                        <span slot="title">Fecha de salida:</span>
                      </IonDatetime>
                    </IonCol>
                  </IonRow>
                  <IonItem>
                    <IonLabel position="floating">
                      Estatus de reservación:
                    </IonLabel>
                    <IonSelect
                      placeholder={t("Seleccione un estatus")}
                      value={estatusReservacion}
                      onIonChange={(e) => setEstatusReservacion(e.detail.value)}
                    >
                      {reservationStatusOptions.map((record: any) => (
                        <IonSelectOption
                          value={record.value}
                          key={record.value}
                        >
                          {record.label}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  </IonItem>
                  <IonItem>
                    <IonTextarea
                      label="Nombre(s) de acompañantes"
                      labelPlacement="stacked"
                      placeholder="Enter Nombre(s) de acompañantes"
                      value={acompanantes}
                      onIonChange={(e) => setAcompanantes(e.detail.value!)}
                    ></IonTextarea>
                  </IonItem>
                  <IonItem>
                    <IonTextarea
                      label="Nota residente"
                      labelPlacement="stacked"
                      placeholder="Nota residente"
                      value={notaResidente}
                      onIonChange={(e) => setNotaResidente(e.detail.value!)}
                    ></IonTextarea>
                  </IonItem>
                  <IonItem>
                    <IonTextarea
                      label="Nota condominio"
                      labelPlacement="stacked"
                      placeholder="Nota condominio"
                      readonly={true}
                      value={notaCondominio}
                      onIonChange={(e) => setNotaCondominio(e.detail.value!)}
                    ></IonTextarea>
                  </IonItem>
                </IonList>
                {loadingReservacion ? (
                  <div className="spin">
                    <IonSpinner></IonSpinner>
                  </div>
                ) : (
                  <IonButton
                    className="custom-button"
                    expand="block"
                    fill="outline"
                    onClick={handleClickGuardarCambios}
                  >
                    <IonIcon icon={calendarIcon} slot="start" />
                    Guardar cambios
                  </IonButton>
                )}
              </form>
            </>
          ) : (
            <IonCard key={3}></IonCard>
          )}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default AirbnbPage;
