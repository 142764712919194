import React, { useState } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonRow,
  IonCol,
  IonGrid,
  IonButtons,
  IonMenuButton,
  IonSpinner,
  IonItem,
  IonLabel,
  IonButton,
  IonIcon,
  IonBadge,
  useIonAlert,
  useIonViewWillEnter,
} from "@ionic/react";
import { trash as trashIcon, addCircle as addCircleIcon } from "ionicons/icons";
import "./Tab1.css";
import { gql, useQuery, useMutation } from "@apollo/client";
import AuthService from "../../services/auth.service";
import Moment from "moment";
import { useTranslation } from "react-i18next";

const GET_USER = gql`
  query usuario($usuarioId: String) {
    viewer {
      usuario(usuarioId: $usuarioId) {
        id
        airbnbReservas {
          edges {
            node {
              id
              nombre
              paterno
              materno
              acompanantes
              comentariosCondominio
              comentarios
              fechaLlegada
              fechaSalida
              fechaRegistro
              estatus
            }
          }
        }
      }
    }
  }
`;

const DELETE_RESERVACION = gql`
  mutation deleteAirbnbReserva($id: ID!) {
    deleteAirbnbReserva(id: $id) {
      success
      airbnbreserva {
        id
      }
    }
  }
`;

const ReservacionesTab: React.FC = () => {
  const { t } = useTranslation();
  const currentUser = AuthService.getCurrentUser();
  const [reservaciones, setReservaciones] = useState<any[]>([]);

  const [presentAlert] = useIonAlert();

  const { loading, error, data, refetch } = useQuery(GET_USER, {
    variables: { usuarioId: currentUser.id },
    onCompleted(data) {
      processReservations(data);
      /*setReservaciones([]);
      data.viewer.usuario.airbnbReservas.edges.map((input: any, index: any) => {
        setReservaciones((current) => [
          ...current,
          {
            id: input.node.id,
            nombre: `${input.node.nombre} ${input.node.paterno} ${input.node.materno}`,
            fechaLlegada: input.node.fechaLlegada,
            fechaSalida: input.node.fechaSalida,
            estatus: input.node.estatus,
            //cargo: input.node.monto,
          },
        ]);
      });*/
    },
    onError(error) {
      //reset();
    },
  });

  function processReservations(data: any) {
    setReservaciones([]);
    data.viewer.usuario.airbnbReservas.edges.map((input: any) => {
      setReservaciones((current) => [
        ...current,
        {
          id: input.node.id,
          nombre: `${input.node.nombre} ${input.node.paterno} ${input.node.materno}`,
          fechaLlegada: input.node.fechaLlegada,
          fechaSalida: input.node.fechaSalida,
          estatus: input.node.estatus,
          //cargo: input.node.monto,
        },
      ]);
    });
  }

  useIonViewWillEnter(() => {
    refetch().then(({ data }) => {
      processReservations(data);
    });
  });
  const [
    deleteReservacion,
    {
      data: dataReservacion,
      loading: loadingReservacion,
      error: errorReservacion,
      reset,
    },
  ] = useMutation(DELETE_RESERVACION, {
    onError(error) {
      reset();
    },
    onCompleted() {
      refetch().then(({ data }) => {
        processReservations(data);
      });
    },
  });

  function getColor(estatus: string) {
    switch (estatus) {
      case "R":
        return { color: "warning", label: "Reservado" };
      case "E":
        return { color: "success", label: "Entrada" };
      case "S":
        return { color: "green", label: "Salida" };
      case "C":
        return { color: "danger", label: t("cancelled") };
      default:
        return { color: "danger", label: t("cancelled") };
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle size="large">{t("my-bookings")} [Airbnb]</IonTitle>
          <IonButtons slot="end">
            <IonButton routerLink={`/page/airbnbs/create`}>
              <IonIcon slot="icon-only" icon={addCircleIcon}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonGrid fixed={true}>
          {loading ? (
            <div className="spin">
              <IonSpinner></IonSpinner>
            </div>
          ) : (
            <>
              <IonRow>
                <IonCol size="3">{t("airbnb")}</IonCol>
                <IonCol size="2">{t("date")}</IonCol>
                <IonCol size="2">{t("date")}</IonCol>
                <IonCol size="2">{t("status")}</IonCol>
                <IonCol size="3">{t("cancel")}</IonCol>
              </IonRow>
              {reservaciones.map((input: any, index: any) => (
                <IonRow key={index}>
                  <IonCol size="3">{input.nombre}</IonCol>
                  <IonCol size="2">
                    {Moment(input.fechaLlegada).format("DD-MMM-YY")}
                  </IonCol>
                  <IonCol size="2">
                    {Moment(input.fechaSalida).format("DD-MMM-YY")}
                  </IonCol>
                  <IonCol size="2">
                    <IonItem>
                      <IonLabel>{getColor(input.estatus).label}</IonLabel>
                      <IonBadge color={getColor(input.estatus).color}>
                        {input.estatus}
                      </IonBadge>
                    </IonItem>
                  </IonCol>
                  <IonCol size="3">
                    <IonButton
                      fill="outline"
                      expand="block"
                      routerLink={`/page/airbnbs/${input.id}`}
                    >
                      {t("edit")} {t("airbnb")}
                    </IonButton>
                    <IonButton
                      fill="outline"
                      expand="block"
                      color={"danger"}
                      onClick={(event) =>
                        presentAlert({
                          header: `Estas seguro de eliminar la reservación de ${input.nombre}?`,
                          cssClass: "custom-alert",
                          buttons: [
                            {
                              text: "Cancelar",
                              role: "cancel",
                              cssClass: "alert-button-cancel",
                            },
                            {
                              text: "Aceptar",
                              role: "confirm",
                              cssClass: "alert-button-confirm",
                              handler: () => {
                                deleteReservacion({
                                  variables: { id: input.id },
                                });
                              },
                            },
                          ],
                        })
                      }
                    >
                      <IonIcon icon={trashIcon} />
                    </IonButton>
                  </IonCol>
                </IonRow>
              ))}
            </>
          )}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default ReservacionesTab;
