import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonRow,
  IonCol,
  IonGrid,
  IonButton,
  IonButtons,
  IonMenuButton,
  IonIcon,
  IonSpinner,
  IonRefresher,
  IonRefresherContent,
} from "@ionic/react";
import "./Tab1.css";
import { gql, useLazyQuery } from "@apollo/client";
import AuthService from "../../services/auth.service";
import { refresh as refreshIcon } from "ionicons/icons";
import { RefresherEventDetail } from "@ionic/core";
import { useTranslation, withTranslation } from "react-i18next";

const GET_CATEGORIAS_TIPO_CUOTAS = gql`
  query ($condominioId: Int!) {
    viewer {
      allCatalogoTipoCuotas {
        edges {
          node {
            id
            nombre
            tipoCuota {
              edges {
                node {
                  id
                  nombre
                }
              }
            }
            cuotas(filters: { residenteId: 8, estatusNe: "P" }) {
              edges {
                node {
                  id
                  recargos(filters: { estatus: "D" }) {
                    edges {
                      node {
                        id
                      }
                    }
                  }
                  pagos {
                    edges {
                      node {
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      allTipoCuotas(filters: { condominioId: $condominioId }) {
        edges {
          node {
            id
            nombre
            tipo
            tipoCuotaId
            tipoCuota {
              id
              nombre
            }
            mcuadrado
          }
        }
      }
    }
  }
`;

const SaldoTab: React.FC = () => {
  const { t } = useTranslation();
  const currentUser = AuthService.getCurrentUser();
  const condominioId = currentUser.condominioId ? currentUser.condominioId : 0;
  const [isLoading, setIsLoading] = useState(true);
  const [catalogoTipoCuotaOptions, setCatalogoTipoCuotaOptions] = useState<
    any[]
  >([]);

  const [loadSaldo] = useLazyQuery(GET_CATEGORIAS_TIPO_CUOTAS, {
    fetchPolicy: "cache-and-network",
    variables: { condominioId: condominioId, residenteId: currentUser.id },
    onCompleted(data) {
      console.log(data);
      setIsLoading(false);
      const allCatalogoTipoCuotas = [
        ...data.viewer.allCatalogoTipoCuotas.edges,
      ].map((o) => ({ value: o.node.id, label: o.node.nombre, saldo: 0 }));

      const tipoCuotasExtraOrdinarias = [
        ...data.viewer.allTipoCuotas.edges.filter(
          (o: { node: { tipo: string } }) => o.node.tipo === "O"
        ),
      ].map((o) => ({ value: o.node.id, label: o.node.nombre, saldo: 0 }));

      setCatalogoTipoCuotaOptions([
        ...allCatalogoTipoCuotas,
        ...tipoCuotasExtraOrdinarias,
      ]);
      console.log(catalogoTipoCuotaOptions);

      //reset();
    },
    onError(error) {
      //reset();
    },
  });

  const onRefreshClick = () => {
    setIsLoading(true);
    loadSaldo();
  };

  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    console.log("Begin async operation");
    event.detail.complete();
    setIsLoading(true);
    loadSaldo();
  }

  useEffect(() => {
    console.log("useEffect");
    loadSaldo();
  }, [loadSaldo]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle size="large">
            {t("balance")} [{t("my-finances")}]
          </IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onRefreshClick}>
              <IonIcon slot="icon-only" icon={refreshIcon}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonRefresher
          slot="fixed"
          onIonRefresh={doRefresh}
          pullFactor={0.5}
          pullMin={100}
          pullMax={200}
        >
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <IonGrid fixed={true}>
          {isLoading ? (
            <div className="spin">
              <IonSpinner></IonSpinner>
            </div>
          ) : (
            <>
              <IonRow>
                <IonCol>{t("category")}</IonCol>
                <IonCol>{t("total")}</IonCol>
              </IonRow>
              {catalogoTipoCuotaOptions.map((input: any, index: any) => (
                <IonRow key={index}>
                  <IonCol>{input.label}</IonCol>
                  <IonCol>
                    {new Intl.NumberFormat("es-MX", {
                      style: "currency",
                      currency: "MXN",
                    }).format(input.saldo)}
                  </IonCol>
                </IonRow>
              ))}
            </>
          )}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default withTranslation()(SaldoTab);
