import { useHistory } from "react-router-dom";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonItem,
  IonIcon,
  IonSpinner,
  IonButton,
  IonButtons,
  IonMenuButton,
  IonLabel,
  IonInput,
  IonToggle,
  useIonViewWillEnter,
  IonItemDivider,
  IonList,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import React, { useState } from "react";
import {
  key as keyIcon,
  language as languageIcon,
  moon as moonIcon,
} from "ionicons/icons";
import { gql, useQuery, useMutation } from "@apollo/client";
import "moment/locale/es";
import AuthService from "../services/auth.service";
import { useTranslation, withTranslation, Trans } from "react-i18next";
import "./Pages.css";

const GET_USER = gql`
  query usuario($usuarioId: String) {
    viewer {
      usuario(usuarioId: $usuarioId) {
        id
        bitacoraIncidencias(sort: FECHA_REGISTRO_DESC) {
          edges {
            node {
              incidencia {
                id
                nombre
              }
              fechaIncidencia
              solucion
              estatus
            }
          }
        }
      }
    }
  }
`;

const SINGLE_UPLOAD_MUTATION = gql`
  mutation uploadFileMutation($file: Upload!) {
    uploadFile(file: $file) {
      success
      fileName
    }
  }
`;

interface PageProps {
  pageName: string | undefined;
}

const ConfiguracionPage: React.FC<PageProps> = ({ pageName }, location) => {
  const { t, i18n } = useTranslation();
  const [currentUser, setCurrentUser] = useState(AuthService.getCurrentUser());
  const [image, setImage] = useState("");
  const [file, setFile] = useState(null);
  const [imageChange, setImageChange] = useState(false);
  const [lang, setLang] = useState<string>(
    localStorage.getItem("langSelect")
      ? localStorage.getItem("langSelect")!
      : "en"
  );
  const [text, setText] = useState<string>();
  const [isDarkModeEnable, setIsDarkMode] = useState(
    AuthService.isDarkModeEnable()
  );
  //const [] =
  const { loading, error, data, refetch } = useQuery(GET_USER, {
    variables: { usuarioId: currentUser.id },
    onCompleted(data) {},
    onError(error) {
      //reset();
    },
  });

  const toggleDarkModeHandler = () => {
    if (!isDarkModeEnable) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
    setIsDarkMode(!isDarkModeEnable);
    localStorage.setItem("darkmode", JSON.stringify(!isDarkModeEnable));
    //document.body.classList.toggle("dark");
  };

  const changeLanguage = (lng: string) => {
    setLang(lng);
    localStorage.setItem("langSelect", lng);
    i18n.changeLanguage(lng);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{t("settings")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {loading ? (
          <div className="spin">
            <IonSpinner></IonSpinner>
          </div>
        ) : (
          <>
            <IonCard>
              <IonList>
                <IonItem>
                  <IonIcon
                    slot="start"
                    icon={moonIcon}
                    className="component-icon component-icon-dark"
                  />
                  <IonLabel>{t("dark-mode")}</IonLabel>
                  <IonToggle
                    slot="end"
                    name="darkMode"
                    checked={isDarkModeEnable}
                    onIonChange={toggleDarkModeHandler}
                  />
                </IonItem>
                <IonItemDivider>{t("password-information")}</IonItemDivider>
                <IonItem>
                  <IonLabel position="floating">{t("old-password")}</IonLabel>
                  <IonInput
                    value={text}
                    placeholder={t("old-password")}
                    type={"password"}
                    onIonChange={(e) => setText(e.detail.value!)}
                  ></IonInput>
                </IonItem>

                <IonItem>
                  <IonLabel position="floating">{t("new-password")}</IonLabel>
                  <IonInput
                    value={text}
                    placeholder={t("new-password")}
                    type={"password"}
                    onIonChange={(e) => setText(e.detail.value!)}
                  ></IonInput>
                </IonItem>

                <IonItem>
                  <IonLabel position="floating">
                    {t("confirm-new-password")}
                  </IonLabel>
                  <IonInput
                    value={text}
                    placeholder={t("confirm-new-password")}
                    type={"password"}
                    onIonChange={(e) => setText(e.detail.value!)}
                  ></IonInput>
                </IonItem>
                <IonButton
                  fill="outline"
                  expand="block"
                  routerLink={"/page/tickets/create"}
                >
                  <IonIcon icon={keyIcon} slot="start" />
                  {t("update-password")}
                </IonButton>
              </IonList>
            </IonCard>
            <IonCard>
              <IonList>
                <IonItemDivider>{t("lang-information")}</IonItemDivider>
                <IonSelect
                  value={lang}
                  placeholder="Seleccione una opción"
                  onIonChange={(ev) => changeLanguage(ev.detail.value!)}
                >
                  <IonSelectOption value={"es"}>{"Español"}</IonSelectOption>
                  <IonSelectOption value={"en"}>{"Ingles"}</IonSelectOption>
                </IonSelect>
                <IonButton
                  fill="outline"
                  expand="block"
                  routerLink={"/page/tickets/create"}
                >
                  <IonIcon icon={languageIcon} slot="start" />
                  {t("update-preferred-language")}
                </IonButton>
              </IonList>
            </IonCard>
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default ConfiguracionPage;
